<template>
  <div>
    <div v-show="viewMode==='grid'">
      <grid-toolbar
        :ref="gridToolbarName"
        :page-name="pageName"
        :grid-ref-name="gridName"
        :selected-rows-data="selectedRowsData"
        :title="title">
        <template slot="toolbarSlotBefore">
          <b-button-group
            v-if="gridConfig!==null && gridConfig.actionPageBtn!==undefined"
            size="sm"
          >
            <div
              v-for="(btn, index) in gridConfig.actionPageBtn"
              :key="index">
              <b-button
                v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'service'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :title="btn.title"
                variant="primary"
                size="sm"
                style="margin-right: 2px"
                @click="actionPageClicked(btn.command)"
              >
                <feather-icon
                  :icon="btn.icon"
                  size="12"/>
              </b-button>
            </div>
          </b-button-group>
        </template>
        <template
          v-if="gridConfig!==null && gridConfig.actionMultipleRowBtn!==undefined"
          slot="selectedRowsCommandItem">
          <div
            v-for="(btn, index) in gridConfig.actionMultipleRowBtn"
            :key="index">
            <b-dropdown-item
              v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'service'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
              @click="selectedRowsCommand(btn.command)">
              <span class="text-uppercase">{{ btn.label }}</span>
            </b-dropdown-item>
          </div>
        </template>
      </grid-toolbar>

      <dx-data-grid
        :ref="gridName"
        :on-content-ready="gridContentReady"
        :focused-column-index.sync="focusedRowIndex"
        :on-key-down="gridKeyDown"
        :on-focused-row-changing="gridFocusedRowChanging"
        :on-focused-row-changed="gridFocusedRowChanged"
        :on-focused-cell-changed="gridFocusedCellChanged"
        :on-focused-cell-changing="gridFocusedCellChanging"
        :on-selection-changed="selectionChanged"
        :height="gridHeight">
        <div
          slot="actionTpl"
          slot-scope="data">
          <b-dropdown
            :ref="gridName+'RowAction'+data.data.data.id"
            variant="link"
            no-caret
            size="sm">
            <template
              #button-content
            >
              <feather-icon
                icon="MoreHorizontalIcon"
                size="16"
                class="align-middle text-body p-0"
              />
            </template>
            <div
              v-for="(btn, index) in gridConfig.actionRowBtn"
              :key="index">
              <b-dropdown-item
                v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'service'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
                class=""
                @click="actionRowClicked({mode:btn.command, rowData:data.data.data})">
                <feather-icon :icon="btn.icon"/>
                <span class="align-middle ml-50 text-uppercase">{{ btn.label }}</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </div>

        <div
          slot="hasChildrenTpl"
          slot-scope="data">
          <b-badge
            v-if="data.data.row.data.has_children"
            variant="success">
            YES
          </b-badge>
          <b-badge
            v-else
            variant="danger">
            NO
          </b-badge>
        </div>

        <dx-column-chooser mode="select"/>
      </dx-data-grid>
    </div>
    <div
      v-if="viewMode==='form'"
      class="mb-3">
      <form-app
        :ref="formName"
        :form-data-id="formDataId"
        :title="title"
        :form-name="formName"
        :on-form-close="formOnHide"
        :on-form-add-new="formOnAddNew"
        :on-form-field-data-changed="onFormFieldDataChanged"
        :on-form-before-save="formBeforeSave"
        :form-clone-mode="formCloneMode"
        :has-upload-files="true"
        :active-state-enabled="true">
        <div
          v-if="additionalFormVisible"
          slot="additionalForm">
          <dx-tab-panel
            ref="tabPanel"
            :data-source="[{title:'Working Day Availability', template: 'tab1'}, {title:'Special Day Availability', template: 'tab2'}, {title:'Image', template: 'tab3'}, {title:'Video', template: 'tab4'}]"
            :selected-index="0"
            :animation-enabled="false"
            :element-attr="{class:'mt-1 mb-1'}">
            <template #title="{ data: tabs }">
              <span>
                {{ tabs.title }}
              </span>
            </template>
            <template #tab1>
              <div>
                <b-button
                  v-if="availabilityRules.length===0"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-secondary"
                  block
                  size="sm"
                  @click.prevent="availabilityRulesAdd">Add Rules
                </b-button>
                <div v-show="availabilityRulesSelectedRowsData.length>0">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    size="sm"
                    class="float-right mb-1"
                    @click.prevent.stop="availabilityRulesSelectedRowsCommand">
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-50" /> Delete {{ availabilityRulesSelectedRowsData.length }} Selected Data
                  </b-button>

                  <div class="clearfix"/>
                </div>

                <div v-show="availabilityRules.length>0">
                  <dx-data-grid
                    v-show="availabilityRules.length>0"
                    ref="availabilityRulesGrid"
                    :show-borders="false"
                    :data-source="availabilityRules"
                    :show-row-lines="true"
                    :row-alternation-enabled="false"
                    :repaint-changes-only="true"
                    :element-attr="{class:'nodata-noicon', style:'max-height:319px;'}"
                    :two-way-binding-enabled="false"
                    :on-content-ready="gridContentReadySelectionColumn"
                    :on-selection-changed="availabilityRulesGridSelectionChanged"
                    key-expr="id_tmp"
                    no-data-text="Belum Ada Data">
                    <dx-column
                      :allow-editing="false"
                      caption="#"
                      alignment="center"
                      css-class="cell-editor"
                      cell-template="noTpl"
                      width="40"/>
                    <div
                      slot="noTpl"
                      slot-scope="data">
                      {{ data.data.data.id_tmp + 1 }}
                    </div>

                    <dx-column
                      name="availabilityType"
                      :show-editor-always="true"
                      caption="Range Type"
                      data-field="availabilityType"
                      data-type="string"
                      calculate-display-value="availabilityType"
                      css-class="cell-editor"
                      edit-cell-template="availabilityTypeTpl"/>
                    <div
                      slot="availabilityTypeTpl"
                      slot-scope="data">
                      <dx-select-box
                        :ref="`availabilityRulesGrid_availabilityType_${data.data.rowIndex}`"
                        v-model="data.data.data.availability_type"
                        :element-attr="{'index':data.data.rowIndex, 'elName':'availabilityType'}"
                        :show-clear-button="true"
                        :active-state-enabled="false"
                        :show-data-before-search="false"
                        :show-drop-down-button="true"
                        :open-on-field-click="true"
                        :on-value-changed="availabilityRulesGridValueChanged"
                        :on-enter-key="onEditorEnterKeyCallback"
                        :search-enabled="true"
                        :grouped="true"
                        :value="data.data.data !== undefined && data.data.data.availability_type !== null ? data.data.data.availability_type : null"
                        :data-source="availabilityList"
                        value-expr="id"
                        display-expr="name">
                        <dx-validator :validation-rules="[{ type: 'required', message: '' }]"/>
                      </dx-select-box>
                    </div>

                    <dx-column
                      name="availabilityFrom"
                      caption="From"
                      width="200"
                      :show-editor-always="true"
                      data-field="availabilityFrom"
                      data-type="string"
                      css-class="cell-editor"
                      edit-cell-template="availabilityFromTpl"/>
                    <div
                      slot="availabilityFromTpl"
                      slot-scope="data">
                      <dx-date-box
                        v-if="data.data.data.availability_type === 'custom'"
                        type="datetime"
                        display-format="yyyy-MM-dd HH:mm"
                        :use-mask-behavior="true"
                        :element-attr="{'index':data.data.rowIndex, 'elName':'availabilityFrom'}"
                        :value.sync="data.data.data.from_date"/>
                      <dx-select-box
                        v-if="data.data.data.availability_type === 'months'"
                        :ref="`availabilityRulesGrid_availabilityFrom_${data.data.rowIndex}`"
                        v-model="data.data.data.from_month"
                        :element-attr="{'index':data.data.rowIndex, 'elName':'availabilityFrom'}"
                        :show-clear-button="false"
                        :active-state-enabled="false"
                        :show-data-before-search="false"
                        :show-drop-down-button="true"
                        :open-on-field-click="true"
                        :on-value-changed="availabilityRulesGridValueChanged"
                        :on-enter-key="onEditorEnterKeyCallback"
                        :search-enabled="true"
                        :value="data.data.data !== undefined && data.data.data.from_month !== null ? data.data.data.from_month : null"
                        :data-source="monthList"
                        value-expr="id"
                        display-expr="name">
                        <dx-validator :validation-rules="[{ type: 'required', message: '' }]"/>
                      </dx-select-box>
                      <dx-select-box
                        v-if="data.data.data.availability_type === 'days'"
                        :ref="`availabilityRulesGrid_availabilityFrom_${data.data.rowIndex}`"
                        v-model="data.data.data.from_week_day"
                        :element-attr="{'index':data.data.rowIndex, 'elName':'availabilityFrom'}"
                        :show-clear-button="false"
                        :active-state-enabled="false"
                        :show-data-before-search="false"
                        :show-drop-down-button="true"
                        :open-on-field-click="true"
                        :on-value-changed="availabilityRulesGridValueChanged"
                        :on-enter-key="onEditorEnterKeyCallback"
                        :search-enabled="true"
                        :value="data.data.data !== undefined && data.data.data.from_week_day !== null ? data.data.data.from_week_day : null"
                        :data-source="dayList"
                        value-expr="id"
                        display-expr="name">
                        <dx-validator :validation-rules="[{ type: 'required', message: '' }]"/>
                      </dx-select-box>
                      <dx-date-box
                        v-if="data.data.data.availability_type.match('^time')"
                        type="time"
                        display-format="HH:mm"
                        placeholder="__:__"
                        :use-mask-behavior="true"
                        :element-attr="{'index':data.data.rowIndex, 'elName':'availabilityFrom'}"
                        :value.sync="data.data.data.from_time"/>
                    </div>

                    <dx-column
                      name="availabilityTo"
                      caption="To"
                      width="200"
                      :show-editor-always="true"
                      data-field="availabilityTo"
                      data-type="string"
                      css-class="cell-editor"
                      edit-cell-template="availabilityToTpl"/>
                    <div
                      slot="availabilityToTpl"
                      slot-scope="data">
                      <dx-date-box
                        v-if="data.data.data.availability_type === 'custom'"
                        type="datetime"
                        display-format="yyyy-MM-dd HH:mm"
                        :use-mask-behavior="true"
                        :element-attr="{'index':data.data.rowIndex, 'elName':'availabilityTo'}"
                        :value.sync="data.data.data.to_date"/>
                      <dx-select-box
                        v-if="data.data.data.availability_type === 'months'"
                        :ref="`availabilityRulesGrid_availabilityTo_${data.data.rowIndex}`"
                        v-model="data.data.data.to_month"
                        :element-attr="{'index':data.data.rowIndex, 'elName':'availabilityTo'}"
                        :show-clear-button="false"
                        :active-state-enabled="false"
                        :show-data-before-search="false"
                        :show-drop-down-button="true"
                        :open-on-field-click="true"
                        :on-value-changed="availabilityRulesGridValueChanged"
                        :on-enter-key="onEditorEnterKeyCallback"
                        :search-enabled="true"
                        :value="data.data.data !== undefined && data.data.data.to_month !== null ? data.data.data.to_month : null"
                        :data-source="monthList"
                        value-expr="id"
                        display-expr="name">
                        <dx-validator :validation-rules="[{ type: 'required', message: '' }]"/>
                      </dx-select-box>
                      <dx-select-box
                        v-if="data.data.data.availability_type === 'days'"
                        :ref="`availabilityRulesGrid_availabilityTo_${data.data.rowIndex}`"
                        v-model="data.data.data.to_week_day"
                        :element-attr="{'index':data.data.rowIndex, 'elName':'availabilityTo'}"
                        :show-clear-button="false"
                        :active-state-enabled="false"
                        :show-data-before-search="false"
                        :show-drop-down-button="true"
                        :open-on-field-click="true"
                        :on-value-changed="availabilityRulesGridValueChanged"
                        :on-enter-key="onEditorEnterKeyCallback"
                        :search-enabled="true"
                        :value="data.data.data !== undefined && data.data.data.to_week_day !== null ? data.data.data.to_week_day : null"
                        :data-source="dayList"
                        value-expr="id"
                        display-expr="name">
                        <dx-validator :validation-rules="[{ type: 'required', message: '' }]"/>
                      </dx-select-box>
                      <dx-date-box
                        v-if="data.data.data.availability_type.match('^time')"
                        type="time"
                        display-format="HH:mm"
                        placeholder="__:__"
                        :use-mask-behavior="true"
                        :element-attr="{'index':data.data.rowIndex, 'elName':'availabilityTo'}"
                        :value.sync="data.data.data.to_time"/>
                    </div>

                    <dx-column
                      name="availabilityBookable"
                      caption="Bookable"
                      width="100"
                      :show-editor-always="true"
                      data-field="availabilityBookable"
                      data-type="string"
                      css-class="cell-editor"
                      edit-cell-template="availabilityBookableTpl"/>
                    <div
                      slot="availabilityBookableTpl"
                      slot-scope="data">
                      <dx-select-box
                        :ref="`availabilityRulesGrid_is_bokable_${data.data.rowIndex}`"
                        v-model="data.data.data.is_bokable"
                        :element-attr="{'index':data.data.rowIndex, 'elName':'is_bokable'}"
                        :show-clear-button="false"
                        :active-state-enabled="false"
                        :show-data-before-search="false"
                        :show-drop-down-button="true"
                        :open-on-field-click="true"
                        :on-value-changed="availabilityRulesGridValueChanged"
                        :on-enter-key="onEditorEnterKeyCallback"
                        :search-enabled="true"
                        :value="data.data.data !== undefined && data.data.data.is_bokable !== null ? data.data.data.is_bokable : null"
                        :data-source="yesNoList"
                        value-expr="id"
                        display-expr="name">
                        <dx-validator :validation-rules="[{ type: 'required', message: '' }]"/>
                      </dx-select-box>
                    </div>

                    <dx-column
                      name="actionCol"
                      :allow-sorting="false"
                      :allow-editing="false"
                      caption=""
                      width="60"
                      cell-template="btnTpl"
                      css-class="col-action-xs"
                      alignment="left"/>
                    <div
                      slot="btnTpl"
                      slot-scope="data">
                      <b-button-group size="sm">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="btn-icon"
                          title="Add Row"
                          @click.prevent.stop="availabilityRulesAdd">
                          <feather-icon
                            icon="PlusIcon"
                            size="12" />
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="btn-icon"
                          title="Delete Row"
                          @click.prevent.stop="availabilityRulesDel(data)">
                          <feather-icon
                            icon="Trash2Icon"
                            size="12" />
                        </b-button>
                      </b-button-group>
                    </div>

                    <dx-row-dragging
                      :allow-reordering="true"
                      :on-reorder="availabilityRulesReorder"
                      :show-drag-icons="true"
                      :auto-scroll="true"/>
                    <dx-editing
                      :allow-updating="true"
                      mode="cell"/>
                    <dx-paging :enabled="false"/>
                    <dx-sorting mode="none"/>
                    <dx-selection
                      select-all-mode="allPages"
                      show-check-boxes-mode="always"
                      mode="multiple"/>
                  </dx-data-grid>
                  <div class="py-1">
                    <span class="dx-field-item-label-text font-italic">Note:  In case of conflicting rules, the top rule gets priority.</span>
                  </div>
                </div>
              </div>
            </template>
            <template #tab2/>
            <template #tab3>
              <div>
                <div class="mb-1">
                  <dx-file-uploader
                    ref="fileUploader"
                    :on-value-changed="filesValueChangedImage"
                    :multiple="true"
                    :show-file-list="false"
                    select-button-text="Select Image"
                    label-text=""
                    accept="image/*"
                    upload-mode="useForm"
                  />
                </div>

                <dx-tile-view
                  ref="tileViewImage"
                  :items="serviceImage"
                  :height="206"
                  :base-item-width="180"
                  :base-item-height="180"
                  :item-margin="10"
                  :active-state-enabled="false"
                  :focus-state-enabled="false"
                  :hover-state-enabled="false"
                  direction="vertical"
                  no-data-text=""
                  item-template="tile"
                >
                  <template #tile="{ data }">
                    <div
                      :id="`${formName}Foto-${data.id}`"
                      class="image border"
                      :style="{ 'background-image': `url(${data.filesrc})`, 'height': '180px', 'width': '180px', 'background-position': 'center', 'background-size': 'cover' }">
                      <b-button
                        variant="danger"
                        size="sm"
                        style="position: absolute; right: 5px; top: 5px"
                        @click.prevent.stop="imageDel(data.id)">
                        <feather-icon
                          icon="Trash2Icon"
                          class="mr-50" />
                      </b-button>
                    </div>
                  </template>
                </dx-tile-view>
              </div>
            </template>
            <template #tab4>
              <div>
                <div class="mb-1">
                  <dx-button
                    text="Select Video"
                    @click="showMediaManager = true" />
                </div>
                <div class="mb-1">
                  <span v-if="serviceVideoSingle">{{ serviceVideoSingle }}</span>
                </div>
              </div>
            </template>
          </dx-tab-panel>
        </div>
      </form-app>
    </div>

    <audit-grid
      v-if="viewMode==='audit'"
      :ref="auditGridName"
      :title="title"
      :name="auditGridName"
      :hide-event="formOnHide"/>

    <media-modal
      :visible="showMediaManager"
      size="xl"
      modal-id="home"
      :centered="true"
      :scrollable="true"
      :hide-footer="true"
      @media-modal-close="showMediaManager = false"
    >
      <template slot="modalHeader">
        Media Manager
      </template>
      <template slot="modalBody">
        <media-manager
          :is-modal="true"
          :selected-event-name="selectedEventName"
          @media-modal-close="showMediaManager = false"
        />
      </template>
      <template slot="modalFooter">
        <div class="w-100"/>
      </template>
    </media-modal>
  </div>
</template>

<script>
import { DefaultPageData } from '@/modules/mixins/data'
import yesNoList from '@/modules/mixins/tipeYesNo'
import availabilityList from '@/modules/mixins/tipeAvailability'
import monthList from '@/modules/mixins/tipeMonth'
import dayList from '@/modules/mixins/tipeDays'
import { nextTick } from '@vue/composition-api'
import { DxTabPanel } from 'devextreme-vue/tab-panel'
import { DxFileUploader } from 'devextreme-vue/ui/file-uploader'
import { DxTileView } from 'devextreme-vue/ui/tile-view'
// import { DxSelectBox } from 'devextreme-vue/ui/select-box'
import { DxButton } from 'devextreme-vue/ui/button'
import MediaModal from '@/views/media-manager/MediaModal.vue'
import MediaManager from '@/views/media-manager/MediaManager.vue'
// import { BAlert } from 'bootstrap-vue'

const _ = require('lodash')

export default {
  components: {
    // BAlert,
    DxButton,
    // DxSelectBox,
    DxTabPanel,
    DxFileUploader,
    DxTileView,
    MediaModal,
    MediaManager
  },
  props: {
    pageName: {
      type: String,
      default: 'Service'
    },
    title: {
      type: String,
      default: 'Service'
    },
    gridHeight: {
      type: Number,
      default: function _default() {
        return window.innerHeight - 198
      }
    }
  },
  data() {
    const data = {
      ...DefaultPageData, availabilityList, yesNoList, monthList, dayList
    }
    this.$stateMerge(data, {
      viewMode: 'form',
      gridName: `${this.pageName}Grid`,
      gridToolbarName: `${this.pageName}GridToolbar`,
      actionRowButtonName: `${this.pageName}GridActionRowBtn`,
      auditGridName: `${this.pageName}GridAudit`,
      formName: `${this.pageName}Form`,
      formOnHide: `${this.pageName}FormHideEvent`,
      formOnAddNew: `${this.pageName}FormAddNewEvent`,
      delRowsRoute: 'serviceDelBatch',
      restoreRowsRoute: 'serviceRestoreBatch',
      serviceReorderName: `${this.pageName}ReorderForm`,
      serviceImage: [],
      serviceImageRender: [],
      serviceVideo: [],
      serviceVideoRender: [],

      availability_rules: [],
      availabilityRulesDeleted: [],
      availabilityRulesSelectedRowsData: [],

      // media manager
      serviceVideoSingle: '',
      showMediaManager: false,
      selectedEventName: 'editor'
    })
    return data
  },
  computed: {
    availabilityRules() {
      return this.availability_rules
    },
    availabilityRulesCount() {
      return _.chain(this.availabilityRules).filter(o => o.id_tmp !== null).size().value()
    }
  },
  created() {
    const vm = this
    vm.gridCreated()
  },
  mounted() {
    const vm = this
    vm.gridMount()
    vm.formMount()
  },
  methods: {
    bindMoustrap() {

    },
    actionPageClicked(command) {
      if (command === 'addNew') {
        this.viewMode = 'form'
      } else if (command === 'reorder') {
        this.viewMode = 'reorder'
      }
    },
    actionRowClicked(command) {
      const vm = this
      console.log('actionRowClicked', command)
      setTimeout(() => {
        if (command.mode === 'editRow' || command.mode === 'cloneRow') {
          vm.$refs[`${vm.gridName}RowAction${command.rowData.id}`].hide()
          // eslint-disable-next-line radix
          vm.formDataId = parseInt(command.rowData.id)
          vm.formCloneMode = command.mode === 'cloneRow'
          vm.viewMode = 'form'
        } else if (command.mode === 'deleteRow') {
          vm.delRows([command.rowData.id], false)
        } else if (command.mode === 'restoreRow') {
          vm.restoreRows([command.rowData.id], false)
        } else if (command.mode === 'auditRow') {
          vm.$refs[`${vm.gridName}RowAction${command.rowData.id}`].hide()
          const route = `api/service/${command.rowData.id}/audit`
          vm.viewMode = 'audit'
          nextTick().then(() => {
            vm.$refs[vm.auditGridName].updateRoute(route)
          })
        } else {
          vm.msgShow('Unauthorized access', 'error')
        }
      })
    },
    selectedRowsCommand(command) {
      if (command === 'deleteRow') {
        const vm = this
        setTimeout(() => {
          vm.delRows(this.$_map(this.selectedRowsData, 'id'))
          vm.$refs[vm.gridToolbarName].$refs[`${vm.gridName}SelectedRowsButton`].hide()
        }, 200)
      }
    },
    onFormFieldDataChanged(e) {
      const formRef = this.$refs[this.formName]
      if (e.value === undefined || !formRef.formItemsSetStatus) {
        return false
      }

      if (e.dataField === 'duration') {
        // this.calculateSlotsNum(e.value)
      }

      if (e.dataField === 'category') {
        if (e.value === '1TO1_COACHING') {
          formRef.form.getEditor('max_per_block').option({
            readOnly: true,
            value: 1
          })
        }
        if (e.value === 'GROUP_COACHING') {
          formRef.form.getEditor('max_per_block').option({
            readOnly: false,
            value: 0
          })
        }
      }

      if (e.dataField === 'allow_cancellation') {
        if (e.value === 1) {
          formRef.form.getEditor('cancel_before_start').option({
            readOnly: true
          })
        } else {
          formRef.form.getEditor('cancel_before_start').option({
            readOnly: false
          })
        }
      }
    },
    formMount() {
      this.serviceVideoSingle = ''
      window.eventHub.$on('media-manager-selected-editor', file => {
        // Do something with the file info...
        console.log(file.name)
        console.log(file.mimeType)
        console.log(file.relativePath)
        console.log(file.webPath)

        this.serviceVideoSingle = file.fullPath

        // Hide the Media Manager...
        this.showMediaManager = false
      })

      this.$events.$on(`${this.formName}FormMountedEvent`, () => {
        console.log(`${this.formName}FormMountedEvent`)
        nextTick().then(() => {
          this.additionalFormVisible = true
          if (this.formDataId !== null) {
            this.getSchedule()
            this.getDaysMarkers()
          }

          const vm = this.$refs[this.formName]
          this.serviceImage = []
          const { formDataEdit } = vm
          if (formDataEdit !== undefined && formDataEdit !== null) {
            const availabilityRules = formDataEdit.availability_rules
            availabilityRules.forEach(detail => {
              detail.id_tmp = _.uniqueId()
            })
            vm.availability_rules = availabilityRules

            _.each(formDataEdit.images, val => {
              this.serviceImage.push({
                id: val.id,
                file: null,
                filename: val.file_name,
                filesrc: val.url
              })
            })
            _.each(formDataEdit.videos, val => {
              this.serviceVideo.push({
                id: val.id,
                file: null,
                filename: val.file_name,
                filesrc: val.url
              })
            })
          }
        })
      })
    },
    formBeforeSave() {
      const vm = this
      const { formData } = vm.$refs[vm.formName]

      return new Promise(done => {
        // vm.$refs[vm.formName].formData = {...formData, form_days: this.form_days, form_days_specific: this.form_days_specific}
        // console.log('formData', vm.$refs[vm.formName].formData)
        const fd = new FormData()

        _.each(formData, (val, key) => {
          fd.append(key, val)
        })

        formData.availability_rules = []
        const { availabilityRules } = vm
        availabilityRules.forEach(detail => {
          detail.id_tmp = _.uniqueId()
        })
        formData.availability_rules = availabilityRules
        formData.availability_rules_deleted = vm.availabilityRulesDeleted

        const imagesToEdit = []
        _.each(vm.serviceImage, val => {
          if (val.file !== null && val.file !== undefined) {
            if (val.file.constructor === File) {
              fd.append('files[]', val.file)
            }
          } else {
            imagesToEdit.push(val)
          }
        })

        _.each(vm.serviceVideo, val => {
          if (val.file !== null && val.file !== undefined) {
            if (val.file.constructor === File) {
              fd.append('video', val.file)
            }
          }
        })

        fd.append('serviceVideoSingle', this.serviceVideoSingle)
        fd.append('images', JSON.stringify(imagesToEdit))

        vm.$refs[vm.formName].formDataHasUploadFiles = fd

        done(true)
      }).catch(error => {
        this.msgShow(error, 'error')
        return false
      })
    },
    filesValueChangedImage(e) {
      const vm = this
      // vm.serviceImage = []
      // vm.serviceImageRender = []
      _.each(e.value, (val, index) => {
        const reader = new FileReader()
        reader.onload = ev => {
          vm.serviceImage.push({
            id: _.uniqueId(),
            file: val,
            filename: val.name,
            filesrc: ev.target.result
          })
          this.serviceImageRender[index] = ev.target.result
        }
        reader.readAsDataURL(val)
      })
      vm.$refs.tabPanel.instance.repaint()
    },
    filesValueChangedVideo(e) {
      const vm = this
      vm.serviceVideo = []
      vm.serviceVideoRender = []
      _.each(e.value, (val, index) => {
        const reader = new FileReader()
        reader.onload = ev => {
          vm.serviceVideo.push({
            id: _.uniqueId(),
            file: val,
            filename: val.name,
            filesrc: ev.target.result
          })
          this.serviceVideoRender[index] = ev.target.result
        }
        reader.readAsDataURL(val)
      })
      vm.$refs.tabPanel.instance.repaint()
    },
    imageDel(id) {
      console.log(id)
      const vm = this
      vm.serviceImage = _.filter(vm.serviceImage, serviceImage => serviceImage.id !== id)
    },
    videoDel(id) {
      console.log(id)
      const vm = this
      vm.serviceVideo = _.filter(vm.serviceVideo, serviceVideo => serviceVideo.id !== id)
    },
    getCoach() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.coach] : null
    },
    availabilityRulesGridSelectionChanged() {
      this.availabilityRulesSelectedRowsData = this.$refs.availabilityRulesGrid.instance.getSelectedRowsData()
    },
    availabilityRulesAdd() {
      const vm = this
      // const availabilityRulesGrid = vm.$refs.availabilityRulesGrid.instance

      vm.availability_rules.push({
        id_tmp: _.uniqueId(),
        availability_type: 'custom',
        from_date: null,
        from_week_day: null,
        from_month: null,
        from_time: null,
        to_week_day: null,
        to_month: null,
        to_date: null,
        to_time: null,
        is_bokable: 'no'
      })

      setTimeout(() => {
        // availabilityRulesGrid.editCell(vm.availabilityRules.length - 1, 'keterangan')
      }, 500)
    },
    availabilityRulesReorder(e) {
      console.log('/* availabilityRulesReorder ------------------------------------------------------- */')
      console.log(e)
      const visibleRows = e.component.getVisibleRows()
      console.log(visibleRows[e.toIndex].data)
      const toIndex = this.availability_rules.indexOf(visibleRows[e.toIndex].data)
      const fromIndex = this.availability_rules.indexOf(e.itemData)
      const newRules = [...this.availability_rules]

      newRules.splice(fromIndex, 1)
      newRules.splice(toIndex, 0, e.itemData)

      this.availability_rules = newRules
    },
    availabilityRulesSelectedRowsCommand() {
      const vm = this
      const { formData } = vm.$refs[vm.formName]
      console.log('formData', formData)
      console.log('availabilityRules', vm.availabilityRules)
      // this.confirm('Hapus data untuk data yang dipilih').then(resp => {
      //   if (resp) {
      //     this.availabilityRulesDelProcess(this.availabilityRulesSelectedRowsData)
      //   }
      // })
    },
    availabilityRulesDel(data) {
      console.log('availabilityRulesDel', data.data.data)
      const vm = this
      if (data.data.kategori !== null) {
        this.confirm(`Hapus data <br/> <b>${data.data.data.id_tmp}</b>`).then(resp => {
          if (resp) {
            vm.availabilityRulesDelProcess([data.data.data])
          }
        })
      } else {
        vm.availabilityRulesDelProcess([data.data.data])
      }
    },
    availabilityRulesDelProcess(data) {
      const vm = this
      const idDeletedList = _.chain(data).filter(o => o.id !== null).map('id').value()
      const availabilityRulesGrid = vm.$refs.availabilityRulesGrid.instance

      _.remove(vm.availability_rules, o => _.find(data, { id_tmp: o.id_tmp }) !== undefined)

      availabilityRulesGrid.deselectRows(_.map(data, 'id_tmp'))
      vm.availabilityRulesDeleted = _.union(vm.availabilityRulesDeleted, idDeletedList)

      setTimeout(() => {
        if (vm.availabilityRules.length > 0) {
          availabilityRulesGrid.refresh().then(() => {
            const index = vm.availabilityRules.length - 1
            availabilityRulesGrid.focus(availabilityRulesGrid.getCellElement(index, 'availability_type'))
          })
        } else {
          nextTick().then(() => {
            vm.availability_rules = []
          }).then(() => {
            availabilityRulesGrid.refresh()
          })
        }
      }, 100)
    },
    availabilityRulesGridValueChanged(e) {
      if (e.event !== undefined) {
        e.event.preventDefault()
        e.event.stopPropagation()
      }

      // const vm = this
      const el = e.component.instance()
      const { index } = el.option('elementAttr')
      const { elName } = el.option('elementAttr')
      // const availabilityRulesGrid = vm.$refs.availabilityRulesGrid.instance
      // const formRef = this.$refs[this.formName]

      console.log('index', index)
      console.log('elName', elName)
    }
  }
}
</script>

<style scoped>
.tile {
    border-radius: .5em;
    text-align: center;
    color: white;
    background: gray;
}

.inactive {
  background-color: #ddd !important;
  cursor: not-allowed !important;
  color: #ffffff;
}

.inactive:hover {
  border-color: white !important;
}

.unavailable {
  background-color: #c3dab9;
  border-color: #c3dab9;
  color: #ffffff;
}

.unavailable:hover {
  background-color: #78cd51;
  border-color: #78cd51;
  color: #ffffff;
}

.available {
  background-color: #78cd51;
  border-color: #78cd51;
  color: #ffffff;
}

.available:hover {
  background: #c3dab9;
  border-color: #c3dab9;
}

.timeslot {
  float: left;
  width: 50%;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #fff;
  cursor: pointer;
  padding: 3px;
  border-radius: 3px;
}

.timeslot-full-width {
  width: 100% !important;
}

table td {
  padding: 5px !important;
}

.hour {
  font-weight: bold;
  text-align: center;
}

.selected-date {
  border: 1px solid rgb(218, 218, 218);
  padding: 10px;
}

.timeslot-special {
  float: left;
  width: 100%;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #fff;
  cursor: pointer;
  padding: 3px;
  border-radius: 3px;
}

.legend {
  float: left;
  margin-right: 15px;
  display: block;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.selected {
  background: rgb(102, 179, 204);
}

.partial {
  background: rgb(255, 178, 43);
  color: green;
}

.off {
  background: yellow;
}

.dx-tile-content {
  height: 100%;
  padding: 0;
  position: relative;
}

.dx-fileuploader-input {
  opacity: 0;
}

</style>
